import { createAxiosInstance } from 'hooks/useFactory'
import type {
  ADD_REPORT_TO_REPORTS_GRP_DT,
  ADMIN_REPORT,
  ADMIN_REPORTS,
  ASSIGN_USER_DT,
  ASSIGN_USER_GRP_TO_REPORT_DT,
  ASSIGN_USER_GRP_TO_REPORT_GRP_DT,
  ASSIGN_USER_TO_REPORT_GRP_DT,
  CLIENT_REPORTS,
  COGNITO_GRP_DT,
  COGNITO_GRP_MEMBER_DT,
  CREATE_USER_DT,
  DIRECT_PWR_BI_RPT_DT,
  REPORT,
  REPORTS_GRP_DT,
  REPORT_PARAMS,
  RETURNED_REPORTS_GRP_DT,
  USER_DT,
  UserOrGroupReport,
  ORG_ID,
  PROPMAN_DT,
  PROPMAN_ORGS_DT,
  ORG_MEMBER_DT,
  ORG_MEMBERS_DT
} from 'types'

const axiosInstance = createAxiosInstance()

async function activateUsers (users: USER_DT[]): Promise<string> {
  const path = '/activate-users'
  const response = await axiosInstance.post(path, users)
  console.log(response)
  return response.data.message
}

async function addReport (data: ASSIGN_USER_DT): Promise<string> {
  const path = '/add-report'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function assignUserGroups (payload: { reports: ADMIN_REPORT[], groups: COGNITO_GRP_DT[] }): Promise<string> {
  const path = '/assign-by-usr-groups-if-any-users'
  const response = await axiosInstance.post(path, payload)
  console.log(response)
  return response.data.message
}

async function addUsersToCognitoGroups (payload: { groups: COGNITO_GRP_DT[], users: USER_DT[] }): Promise<string> {
  const path = '/add-users-to-cognito-groups-n-assign-reports-if-any-reports'
  const response = await axiosInstance.post(path, payload)
  console.log(response)
  return response.data.message
}

async function removeUserFromCognitoGroup (payload: COGNITO_GRP_MEMBER_DT[]): Promise<string> {
  const path = '/remove-user-from-cognito-group'
  const response = await axiosInstance.post(path, payload)
  console.log(response)
  return response.data.message
}

async function assignUsers (payload: { reports: ADMIN_REPORT[], users: USER_DT[] }): Promise<string> {
  const path = '/assign-users'
  const response = await axiosInstance.post(path, payload)
  console.log(response)
  return response.data.message
}

async function createCognitoUserGroup (data: COGNITO_GRP_DT): Promise<string> {
  const path = '/create-user-group'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function createPropmanOrg (data: PROPMAN_DT): Promise<string> {
  const path = '/create-propman-org'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function createUser (data: CREATE_USER_DT): Promise<string> {
  const path = '/create-user'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function deactivateUsers (users: USER_DT[]): Promise<string> {
  const path = '/deactivate-users'
  const response = await axiosInstance.post(path, users)
  console.log(response)
  return response.data.message
}

async function deleteReports (data: any): Promise<string> {
  const path = '/delete-reports'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function deleteSelectedUsers (users: USER_DT[]): Promise<string> {
  const path = '/delete-selected-users'
  const response = await axiosInstance.post(path, users)
  console.log(response)
  return response.data.message
}

async function getEmbeddedReport (param: REPORT_PARAMS): Promise<string> {
  const path = '/view-report'
  try {
    const response = await axiosInstance.get<any>(path, { params: param })
    return response.data.embed_url
  } catch (error) {
    console.error('Error in getEmbeddedReport:', error)
    throw new Error('Unable to get report from the server. If this persists, contact us.')
  }
}

async function getAdminEmbeddedReport (param: REPORT_PARAMS): Promise<string> {
  const path = '/admin-view-report'
  try {
    const response = await axiosInstance.get<any>(path, { params: param })
    return response.data.embed_url
  } catch (error) {
    console.error('Error in getEmbeddedReport:', error)
    throw new Error('Unable to get report from the server. Ask DevOps to check the logs.')
  }
}

async function getUser (): Promise<USER_DT> {
  const path = '/get-user'
  const response = await axiosInstance.get(path)
  return response.data.user
}

async function listAdminReport () {
  const path = '/list-reports'
  const response = await axiosInstance.get<ADMIN_REPORTS>(path)
  console.log('This is the report.data.body', response.data)
  return response.data
}

async function adminListUserReportsReport (params: { user_id: string }): Promise<CLIENT_REPORTS> {
  const path = '/admin-list-user-reports'
  const response = await axiosInstance.get<CLIENT_REPORTS>(path, { params })
  console.log('This is the report.data.body', response.data)
  return response.data
}

async function adminListGroupReportsReport (params: { user_group_id: string }): Promise<CLIENT_REPORTS> {
  const path = '/admin-list-group-reports'
  const response = await axiosInstance.get<CLIENT_REPORTS>(path, { params })
  console.log('This is the report.data.body', response.data)
  return response.data
}

async function listDirectPowerBiReportsFromPwrBiService (): Promise<DIRECT_PWR_BI_RPT_DT[]> {
  const path = '/list-direct-pwr-bi-reports'
  const response = await axiosInstance.get(path)
  console.log('This is the report.data.body', response.data)
  return response.data
}
async function listClientReport () {
  const path = '/list-user-reports'
  const response = await axiosInstance.get<CLIENT_REPORTS>(path)
  console.log('This is the report.data.body', response.data)
  return response.data
}
async function listCognitoUserGroups (): Promise<COGNITO_GRP_DT[]> {
  const path = '/list-user-groups'
  const response = await axiosInstance.get(path)
  console.log(response)
  return response.data.groups
}

async function listCognitoGroupMembers (param: { group_name: string }): Promise<COGNITO_GRP_MEMBER_DT[]> {
  const path = '/list-group-members'
  const response = await axiosInstance.get(path, { params: param })
  console.log(response)
  return response.data.group_users
}

async function listOrgMembers (param: { org_id: string }): Promise<ORG_MEMBERS_DT> {
  const path = '/list-org-members'
  const response = await axiosInstance.get(path, { params: param })
  console.log(response)
  return response.data
}

async function listUserOrgs (param: { usr_id: string }): Promise<ORG_MEMBERS_DT> {
  const path = '/list-user-orgs'
  const response = await axiosInstance.get(path, { params: param })
  console.log(response)
  return response.data
}

async function listCognitoGroupsForAUser (param: { client_sub: string }): Promise<COGNITO_GRP_DT[]> {
  const path = '/list-groups-for-user'
  const response = await axiosInstance.get(path, { params: param })
  console.log(response)
  return response.data.groups
}

async function listUsers (): Promise<USER_DT[]> {
  const path = '/list-users'
  const response = await axiosInstance.get(path)
  console.log(response)
  if (!Array.isArray(response.data.users)) {
    throw new Error('Expected an array of users')
  }
  const listOfUsers = response.data
  return listOfUsers.users
}
async function listReportUsers (param: { report_id: string }): Promise<USER_DT[]> {
  const path = '/list-report-users'
  const response = await axiosInstance.get(path, { params: param })
  console.log(response)
  if (!Array.isArray(response.data.users)) {
    throw new Error('Expected an array of users')
  }
  const listOfUsers = response.data
  return listOfUsers.users
}

async function updateUserAttributes (data: any): Promise<string> {
  const path = '/update-user'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}
async function uploadUsersFromCSV (data: any): Promise<string> {
  console.log('This is the payload from upload users: ', data)
  const path = '/upload-users'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function uploadReport (data: any): Promise<string> {
  console.log('This is the payload from upload uploadReport: ', data)
  const path = '/upload-reports'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}
async function updateReportAttributes (data: any): Promise<string> {
  const path = '/update-client-report-attr'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}
async function deleteSelectedUserReports (data: UserOrGroupReport[]): Promise<string> {
  const path = '/remove-selected-reports-from-user-reports'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function deleteSelectedUserGroupReports (data: UserOrGroupReport[]): Promise<string> {
  const path = '/remove-selected-reports-from-user-group-reports'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function deleteSelectedUserGroups (data: COGNITO_GRP_DT[]): Promise<string> {
  const path = '/delete-selected-user-groups'
  const response = await axiosInstance.post(path, data)
  console.log(response)
  return response.data.message
}

async function getOrganizationIDs (): Promise<ORG_ID> {
  const path = '/list-propman-orgs'
  const response = await axiosInstance.get(path)
  console.log(response.data)
  return response.data.reports
}

async function getOrganizations (): Promise<PROPMAN_ORGS_DT> {
  const path = '/list-propman-orgs'
  const response = await axiosInstance.get(path)
  console.log(response.data)
  return response.data
}

export {
  activateUsers,
  addReport,
  addUsersToCognitoGroups,
  assignUsers,
  createCognitoUserGroup,
  createUser,
  deactivateUsers,
  deleteReports,
  deleteSelectedUsers,
  getEmbeddedReport,
  getUser,
  listAdminReport,
  listClientReport,
  listCognitoUserGroups,
  listUsers,
  updateUserAttributes,
  uploadUsersFromCSV,
  assignUserGroups,
  uploadReport,
  listDirectPowerBiReportsFromPwrBiService,
  getAdminEmbeddedReport,
  removeUserFromCognitoGroup,
  listCognitoGroupsForAUser,
  adminListUserReportsReport,
  updateReportAttributes,
  deleteSelectedUserReports,
  deleteSelectedUserGroups,
  adminListGroupReportsReport,
  listCognitoGroupMembers,
  deleteSelectedUserGroupReports,
  getOrganizationIDs,
  createPropmanOrg,
  getOrganizations,
  listOrgMembers,
  listUserOrgs,
  listReportUsers
}
