const ampConfig = {
  Auth: {
    region: 'eu-west-2',
    identityPoolRegion: 'eu-west-2',
    userPoolId: 'eu-west-2_c0E4rjGsu',
    userPoolWebClientId: '5ng332cb34usprlds339u704bf',
    oauth: {
      domain: 'https://dashboards.prop-bi.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: 'https://dashboards.prop-bi.com/',
      redirectSignOut: 'https://dashboards.prop-bi.com/login',
      responseType: 'code'
    }
  }
}

export { ampConfig }
