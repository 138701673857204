import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Modal, Table, message, Space, notification } from 'antd'
import type { ADMIN_REPORT, USER_DT } from 'types'
import { PageTitle } from 'components'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useNotifications } from 'hooks'
import { assignUsers, listUsers } from 'services'
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface GroupColumn {
  key: string
  title: string
  dataIndex: string
  sorter?: (a: any, b: any) => number
  responsive?: Breakpoint[]
  render?: (text: any, report: any) => JSX.Element
}

interface UsersModalProps {
  visible: boolean
  reports: ADMIN_REPORT[]
  onClose: () => void
}

const UserAssignmentModal: React.FC<UsersModalProps> = ({ visible, reports, onClose }) => {
  const [loading, setLoading] = useState(false)
  const { addNotification } = useNotifications()
  const [users, setUsers] = useState<USER_DT[]>([])
  const [selectedGroups, setSelectedGroups] = useState<USER_DT[]>([])
  const handleSelect = (e: CheckboxChangeEvent, selectedUser: USER_DT) => {
    if (e.target.checked) {
      setSelectedGroups(prev => [...prev, selectedUser])
    } else {
      setSelectedGroups(prev => prev.filter(user => user.sub !== selectedUser.sub))
    }
  }
  const userReportsColumns: GroupColumn[] = [
    {
      key: 'id',
      title: 'Select',
      dataIndex: 'id',
      render: (_: any, record: any) => <Checkbox style={{
      }} onChange={e => { handleSelect(e, record) }} />
    },
    {
      title: 'Given Name',
      dataIndex: 'given_name',
      key: 'given_name',
      sorter: (a, b) => a.given_name.localeCompare(b.given_name)
    },
    {
      title: 'Family Name',
      dataIndex: 'family_name',
      key: 'family_name',
      responsive: ['lg']
    }
  ]

  async function handleAssignSelectedUser (): Promise<void> {
    if (selectedGroups.length <= 0) {
      void message.error('Select users to assign')
    } else {
      try {
        setLoading(true)
        const payload = {
          reports,
          users: selectedGroups
        }
        const msg = await assignUsers(payload)
        addNotification({
          id: Date.now(),
          type: 'info',
          message: `${msg}`,
          timestamp: Date.now()
        })
        void message.info(msg)
        onClose()
      } catch (error: any) {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
        void message.error(error?.message ?? 'There was an error assigning the selected reports to the users')
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const listOfUsers = await listUsers()
        setUsers(listOfUsers)
      } catch (error) {
        console.error('Error fetching the users', error)
        try {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
        } catch (innerError) {
          console.log(innerError)
        }
        notification.error({
          message: 'Error getting users',
          description: `${error}.`
        })
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <PageTitle text="Users" />
      {
                (selectedGroups && selectedGroups.length > 0)
                  ? <>
                        <Space>
                            <Button style={{
                              color: '#d67632'
                            }} loading={loading} type="link" onClick={handleAssignSelectedUser}>Assign</Button>
                        </Space>
                    </>
                  : null
            }
      <Table
        dataSource={users}
        columns={userReportsColumns}
        rowKey="sub"
        bordered
        pagination={{ pageSize: 10, responsive: true }}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default UserAssignmentModal
